import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class NewsCambodian extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Enserv’news March 4, 2024</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/newsall">News</Link></li>
                                        <li>March 4, 2024</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="title">"Enserv Holding Australia Explores Sustainable Investment<br/>in Cambodian Sorghum Industry,<br/> Engages with Prime Minister Hun Manet"</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                                <div className="col-md-12 col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">

                                            <br/>

                                            <div className="section-content row wraper center">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/newscambodian/Ensh_Cambodia2_KJ.jpeg")} alt="center" />
                                                </div>

                                                <div className="col-lg-6 col-md-12 indent-50">
                                                <h2 className="title-ourservice">March 4, 2024</h2>
                                                <p align="justify">Enserv Holding Australia is making a strategic foray into Cambodia's sorghum sector, with plans revealed during a meeting with Prime Minister Hun Manet at the ASEAN-Australia Special Summit in Melbourne. Founder Mr. Tanachat Pochana outlined the company's vision for sustainable agro-industry, focusing on sorghum cultivation and processing. This move aims to capitalize on sorghum's versatility and reduced water requirements compared to rice, aligning with global environmental goals.</p><br/>
                                                <p align="justify">The investment aims to develop sorghum-based products, including a low-calorie, high-protein rice alternative and biogas, contributing to carbon dioxide absorption. Prime Minister Hun Manet welcomed the initiative, recognizing its potential in enhancing Cambodia's agricultural, energy, and technology sectors. He encouraged collaboration with key ministries for seamless alignment with development policies.</p><br/>
                                                <p align="justify">Enserv Holding's interest in Cambodia's sorghum sector highlights the country's appeal for sustainable investments. The initiative promises to bolster Cambodia's economy, support local farmers, and contribute to global environmental efforts. This investment could mark a significant milestone in Cambodia's agricultural evolution, solidifying ties with Australian businesses.</p>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="section-content row wraper">
                                                <div className="col-lg-12 col-md-12 indent-50">
                                                    <p align="justify">The move signifies not just economic and environmental benefits but also underscores the deepening relationship between Cambodia and Australia. As discussions progress, the collaboration between Enserv Holding and Cambodia holds the promise of a sustainable and prosperous future, contributing to local communities and global sustainability goals.</p>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="section-content row wraper">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/newscambodian/Ensh_Cambodia3_KJ.jpeg")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/newscambodian/Ensh_Cambodia4_KJ.jpeg")} alt="center" />
                                                </div>
                                            </div>

                                            <div className="section-content row wraper">
                                                <div className='col-lg-12 col-m-12'>
                                                <br/>
                                                <h5> Source : </h5>
                                                <p>
                                                    <a href={"https://bnnbreaking.com/world/australia/enserv-holding-australia-eyes-investment-in-cambodias-sorghum-sector-meets-pm-hun-manet"} target={"_blank"}>https://bnnbreaking.com</a><br/>
                                                    <a href={"https://www.khmertimeskh.com/501450457/pm-meets-top-australian-business-leaders/"} target={"_blank"}>https://www.khmertimeskh.com</a><br/>
                                                    <a href={"https://eacnews.asia/home/details/28828"} target={"_blank"}>https://eacnews.asia</a>
                                                </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default NewsCambodian;